@import "@/assets/sass/vendors/vue/vuetify/variables.scss";



















































































































































































































































































































































































































































































































































































@import '@/assets/sass/pages/wizard/wizard-1.scss';
.btn-warning {
  background: #e45e00 !important;
}
