@import "@/assets/sass/vendors/vue/vuetify/variables.scss";






































#map {
  width: 100%;
  height: 500px;
}
